import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: []
    },
    reducers: {
        addToCart(state, action) {
            const product = action.payload;
            console.log(product)

            // Trava de loja
            let store_id = (localStorage.getItem('store_id') === null)?null:parseInt(localStorage.getItem('store_id'))

            if (store_id === null){
                localStorage.setItem('store_id', product.store_id)
            } else if (store_id !== null && store_id !== product.store_id){
                cogoToast.warn('Você só pode adicionar no carrinho produtos de uma loja por vez.', {position:"bottom-left"})
                return 
            }

            // if(!product.variation){
            if(false){
                // rever ao liberar variações de produtos
                const cartItem = state.cartItems.find(item => item.id === product.id);
                if(!cartItem){
                    state.cartItems.push({
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuidv4()
                    });
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if(item.cartItemId === cartItem.cartItemId){
                            return {
                                ...item,
                                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
                            }
                        }
                        return item;
                    })
                }

            } else {
                // Procura o item no carrinho
                const cartItem = state.cartItems.find(
                    item =>
                        item.id === product.id &&
                        // product.selectedProductColor &&
                        // product.selectedProductColor === item.selectedProductColor &&
                        // product.selectedProductSize &&
                        // product.selectedProductSize === item.selectedProductSize &&
                        (product.cartItemId ? product.cartItemId === item.cartItemId : true)
                );

                // Se o item não existe, adciona.
                if(!cartItem){
                    state.cartItems.push({
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: product.id
                    });
                // verificar quando liberar variações
                } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
                    // state.cartItems = [
                    //     ...state.cartItems,
                    //     {
                    //         ...product,
                    //         quantity: product.quantity ? product.quantity : 1,
                    //         cartItemId: uuidv4()
                    //     }
                    // ]
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if(item.cartItemId === cartItem.cartItemId){
                            return {
                                ...item,
                                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
                                selectedProductColor: product.selectedProductColor,
                                selectedProductSize: product.selectedProductSize
                            }
                        }
                        return item;
                    });
                }
            }

            cogoToast.success("Produto adicionado ao carrinho!", {position: "bottom-left"});
        },
        deleteFromCart(state, action) {
            state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
            cogoToast.error("Produto removido do seu carrinho.", {position: "bottom-left"});

            if (state.cartItems.length === 0){
                localStorage.removeItem('store_id')
            }
        },
        decreaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
                cogoToast.error("Produto removido do seu carrinho.", {position: "bottom-left"});
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.cartItemId === product.cartItemId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
                cogoToast.warn("A quantidade desse produto foi diminuída", {position: "bottom-left"});
            }
        },
        deleteAllFromCart(state){
            state.cartItems = []
            localStorage.removeItem('store_id')
        }
    },
});

export const { addToCart, deleteFromCart, decreaseQuantity, deleteAllFromCart } = cartSlice.actions;
export default cartSlice.reducer;
