import { Suspense, lazy, useEffect, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { Modal, ModalBody } from "react-bootstrap";
import toast from "react-hot-toast";
import { sendRequest } from "./helpers/requests";
import { deleteAllFromCart } from "./store/slices/cart-slice";

// home pages
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));

// shop pages

const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Lojas = lazy(() => import("./pages/shop/Lojas"));
const Products = lazy(() => import("./pages/shop/ShopGridNoSidebar"));

// Paginas de pagamentos
const PixPayment = lazy(() => import("./pages/payments/Pix"));
const Pedido = lazy(() => import("./pages/pedidos/Pedido"));
const MeusPedidos = lazy(() => import("./pages/pedidos/MeusPedidos"));

const App = () => {

  const [addressModal, setAddressModal] = useState(false)
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])

  const [estadoSelecionado, setEstadoSelecionado] = useState(null)
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null)


  setDefaults({
    key: "AIzaSyDgWPo2Ke8nzf6HnzdNGKvYhHXdIX_y9ZE", // Your API key here.
    language: "pt-br", // Default language for responses.
    region: "pr", // Default region for responses.
  });

  const getCidadesDoEstado = async () => {
    const req = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionado}/distritos`)
    const cidades = await req.json()

    setCidades(cidades.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1
      }
      if (a.nome < b.nome) {
        return -1
      }

      return 0
    }))
  }

  const saveEstado = (estado) => {
    localStorage.setItem('estado', estado)
    setEstadoSelecionado(estado)
  }

  const saveCidade = (cidade) => {
    localStorage.setItem('cidade', cidade)
    setCidadeSelecionada(cidade)
  }

  const handleManualLocation = async () => {
    const req = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    const estados = await req.json()

    setAddressModal(true)

    setEstados(estados.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1
      }
      if (a.nome < b.nome) {
        return -1
      }

      return 0
    }))
  }

  const handleLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( async (position) => {

          const address = await fromLatLng(position.coords.latitude , position.coords.longitude)

          const full_address = address.results[0].formatted_address

          localStorage.setItem('userAddress', full_address)
          pesquisarPorEnderecoCompleto(true)

        }, (error) => {
          
          if (error.code == 1) {
            handleManualLocation()
          }
        });
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
  }

  const pesquisarPorEnderecoCompleto = async (first) => {

    const uf_or_full_address = localStorage.getItem('userAddress')

    if (uf_or_full_address) {
      const request = await sendRequest('home/get-initial-data', 'POST', {
        product_limit: 100,
        uf_or_full_address
      })

      const response = await request

      localStorage.setItem('banners', JSON.stringify(response.response.banners))
      localStorage.setItem('categories', JSON.stringify(response.response.categories))
      localStorage.setItem('nearbyStores', JSON.stringify(response.response.nearbyStores))
      localStorage.setItem('nationalProducts', JSON.stringify(response.response.productsNationalShipping))
    }

    if (first){
      window.location.reload()
    }
  }

  const pesquisarPorCidadeEEstado = async (first) => {
    let cidade = localStorage.getItem('cidade')
    let estado = localStorage.getItem('estado')

    if (cidade && estado) {
      const request = await sendRequest('home/get-initial-data', 'POST', {
        product_limit: 100,
        uf_or_full_address:estadoSelecionado,
        city:cidadeSelecionada
      })

      const response = await request

      localStorage.setItem('banners', JSON.stringify(response.response.banners))
      localStorage.setItem('categories', JSON.stringify(response.response.categories))
      localStorage.setItem('nearbyStores', JSON.stringify(response.response.nearbyStores))
      localStorage.setItem('nationalProducts', JSON.stringify(response.response.productsNationalShipping))

      setAddressModal(false)

    } else{
      toast.error('Você precisa selecionar o estado e a cidade.')
    }

    if (first){
      window.location.reload()
    }
    
  }

  useEffect(() => {
    let full_address = localStorage.getItem('userAddress')
    let cidade = localStorage.getItem('cidade')
    let estado = localStorage.getItem('estado')

    setCidadeSelecionada(cidade)
    setEstadoSelecionado(estado)

    if (!full_address && !cidade && !estado) {
      handleLocation()
    }else if (full_address) {
      pesquisarPorEnderecoCompleto(false)
    }else if (cidadeSelecionada && estadoSelecionado) {
      pesquisarPorCidadeEEstado(false)
    }
  }, [cidadeSelecionada, estadoSelecionado])
  
  useEffect(() => {
    getCidadesDoEstado()
  }, [estadoSelecionado])

  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              {/* Used Routes */}
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFurnitureTwo/>}
              />

                <Route
                    path={process.env.PUBLIC_URL + "/produtos"}
                    element={<ShopGridNoSidebar/>}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/categorias/:id/:category"}
                    element={<Lojas/>}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/loja/:id/:nome"}
                    element={<Products/>}
                />

              {/* Shop pages */}
              <Route
                path={process.env.PUBLIC_URL + "/produtos"}
                element={<ShopGridNoSidebar/>}
              />
              

              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/pagamento/pix"}
                element={<PixPayment />}
              />


              {/* Users Routes */}
              <Route
                path={process.env.PUBLIC_URL + "/meus-pedidos"}
                element={<MeusPedidos />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/pedido/:id"}
                element={<Pedido />}
              />




              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                element={<BlogStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                element={<BlogRightSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard/>}
              /> 

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout/>}
              /> 

              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>

        <Modal show={addressModal} onHide={() => {}} className="product-quickview-modal-wrapper">
          <Modal.Header closeButton={false}>
            <h3 class="text-center">Precisamos saber a sua localização!</h3>
          </Modal.Header>
          <ModalBody>
            <div className="row">

              <div className="col-lg-6 col-sm-12">
                <label>Selecione Seu Estado</label>
                <select className="form-control" onChange={(e) => saveEstado(e.target.value)}>
                    <option>Selecione seu estado</option>
                    {estados.map((item, key) => (
                      <option key={key} value={item.sigla}>{item.nome}</option>
                    ))}
                </select>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label>Selecione Sua Cidade</label>
                <select className="form-control" onChange={(e) => saveCidade(e.target.value)}>
                    <option>Selecione um esdado primeiro</option>
                    {cidades.map((item, key) => (
                      <option key={key} value={item.nome}>{item.nome}</option>
                    ))}
                </select>
              </div>

              <div className="col-12">
                <button className="mt-5 btn btn-primary btn-block" style={{width:'100%'}} disabled={(cidadeSelecionada == null)??false} onClick={() => pesquisarPorCidadeEEstado(true)}>Salvar</button>
              </div>

            </div>

          </ModalBody>
        </Modal>

      </Router>
  );
};

export default App;